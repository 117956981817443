import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	RouterProvider,
	createBrowserRouter,
} from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

// styles
import './index.scss';
import 'swiper/css';

// Pages
import { Homepage } from './Pages/Homepage/Homepage';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
	{
		path: '/',
		element: <Homepage />,
	},
]);

root.render(
	<TonConnectUIProvider manifestUrl="https://tonwinners.com/tonconnect-manifest.json">
		<RouterProvider router={router} />
	</TonConnectUIProvider>,
);
