import mainImage from '../../../Assets/Images/main image.png';
import secondImage from '../../../Assets/Images/image2.png';
import thirdImage from '../../../Assets/Images/image3.png';

export const MainBlock = () => {
    return (
        
				<section className="main">
                <div className="main__leftside">
                    <h1 className="main__title">
                        Discover NFTs & <span>Great Artists</span> on TON Blockchain
                    </h1>
                    <p>
                        Regular cash drops for NFT holders from our launchpad, collaborating with top-notch artists who have something to say
                        through their work. Deflationary model for each collection and many other revolutionary approaches in the NFT industry
                    </p>
                    <div className="main__buttons">
                        <a href="https://getgems.io/user/tonwinners" target="_blank">
                            <button className="blueButton main__btn">Let's Explore</button>
                        </a>
                        <a href="https://getgems.io/" target="_blank">
                            <button className="blueButton main__btn">Sell NFT</button>
                        </a>
                    </div>
                </div>

                <div className="main__rightside">
                    <img src={mainImage} alt="" />
                    <img src={secondImage} alt="" />
                    <img src={thirdImage} alt="" />
                </div>
            </section>
    )
}