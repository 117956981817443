import { Header } from '../../Components/Header';



import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Shine } from '../../Components/Shine';

import avatar from '../../Assets/Images/avatar.png';
import winnersBg from '../../Assets/collection_01.png';
import line from '../../Assets/Images/lines.png';


import { TopProfit } from './Components/TopProfit';

import { ExploreBlock } from './Components/ExploreBlock';
import { MainBlock } from './Components/MainBlock';



const top = [
	{
		name: 'TON INVESTORS',
		total: 2900,
		sector: 'total volume',
		followed: false,
		image: avatar,
		link: 'https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz',
		bgImage:
			'https://i.getgems.io/_Y2hIVG0MoTwHFgECPgJv7JKkbEM8QFgquvhFXoWGHo/rs:fill:600:400:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYy82NWQ0YTYyYWI3ZWUyODhhM2Y4OWQzNjAvZWRpdC9pbWFnZXMvNTA4NjAwLnBuZw',
	},
	{
		name: "WINNER'S TICKETS ",
		total: 338,
		sector: 'total volume',
		followed: false,
		image: avatar,
		link: 'https://getgems.io/collection/EQAymKiwtvrHB-2_L5t4ixD5YPYgYLlhBc5VoGgdotL4QAJH',
		bgImage: winnersBg,
	},
];

const tabs = ['Collectibles', 'Art', 'Gaming', 'Sports', 'Photography', 'Music', 'Virtual Worlds', 'Trading Cards'];
export const Homepage = () => {
	const [activeTab, setActiveTab] = useState('Art');

	const handleTabChange = (index) => {
		setActiveTab(tabs[index]);
	};
	return (
		<div className="wrapperOuter">
			<div className="wrapper">
				<Header />
				<MainBlock />
				<TopProfit />
				<ExploreBlock />


				<section className="top">
					<div className="titleTier2">
						<Shine
							propStyles={{
								width: 1328,
								height: 1000,
								opacity: 0.16,
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								background: 'radial-gradient(34.98% 34.98% at 50% 50%, #F3BA2F 0%, rgba(6, 19, 45, 0) 100%)',
							}}
						/>
						TOP Collections
					</div>

					{/* УБРАНО ПО ТРЕБОВАНИЮ ЗАКАЗЧИКА */}
					{/* <div className="top__buttons">
						<button className="blueButton" style={{ height: 45 }}>
							Popular
						</button>
						<button className="transpButton">Following</button>
					</div> */}

					<Swiper
						className="top__slider"
						spaceBetween={20}
						breakpoints={{ 0: { slidesPerView: 1 }, 575: { slidesPerView: 2 }, 1219: { slidesPerView: 2 } }}
					>
						{top.map((item, index) => {
							return (
								<SwiperSlide key={index} className="top__slider-slide">
									<div className="slide-image-block">
										<img src={item.bgImage} alt="" />
									</div>

									<div className="slide-avatar">
										<img src={item.image} alt="" />
									</div>

									<p className="text24">{item.name}</p>
									<p className="slide-points">
										{parseInt(item.total) > 1000 ? item.total / 1000 + 'K' : item.total}
										<span> {item.sector}</span>
									</p>
									<a target="_blank" href={item.link}>
										<button onClick={(e) => null} className="slide-button blueButton">
											More
										</button>
									</a>
								</SwiperSlide>
							);
						})}
					</Swiper>
				</section>

				<section className="sub">
					<Shine
						propStyles={{
							width: 2866,
							height: 2866,
							top: '50%',
							left: '50%',
							transform: 'translate(-20%, -55%)',
							opacity: 0.3,
						}}
					/>

					<Shine
						propStyles={{
							width: 2866,
							height: 2866,
							top: '-200%',
							left: '-100%',
							transform: 'translate(0,0)',
							opacity: 0.4,
						}}
					/>
					<div className="sub__wrapper">
						<p className="sub__title">Never miss a drop!</p>

						<p className="sub__description">Subscribe to super-exclusive drop list and be the first to know about upcoming drops</p>
						<div className="form">
							{/* <input type="text" placeholder="Enter your Telegram address" /> */}
							<a target="_blank" href="https://t.me/ton_winners">
								<button className="blueButton">Subsribe</button>
							</a>
						</div>
					</div>

					<img
						src={line}
						alt=""
						style={{
							pointerEvents: 'none',
							zIndex: 0,
							position: 'absolute',
							bottom: '-20px',
							left: '50%',
							transform: 'translateX(-50%)',
						}}
					/>
				</section>

				<p className="tonwinners">© 2024 TONWINNERS, Inc</p>
			</div>
		</div>
	);
};
