import { Shine } from './Shine';
import { TonConnectButton}  from '@tonconnect/ui-react'

export const Header = () => {
	return (
		<div className="header">
			<Shine
				propStyles={{
					top: 0,
					right: 0,
					transform: 'translate(50%, -50%)',
					width: 1468,
					height: 1468,
				}}
			/>
			<div className="header__leftside">
				<nav className="header__nav">
					<a href="https://getgems.io/user/tonwinners#collections">
						<li className="header__nav-link">Collections</li>
					</a>
					<a href="https://t.me/ton_winners">
						<li className="header__nav-link">Creators</li>
					</a>
					<a href="https://getgems.io/toninvestors">
						<li className="header__nav-link">Investors</li>
					</a>
					<a href="https://getgems.io/collection/EQAymKiwtvrHB-2_L5t4ixD5YPYgYLlhBc5VoGgdotL4QAJH">
						<li className="header__nav-link">Tickets</li>
					</a>
				</nav>
				<h1 className="header__title">ton winners</h1>
			</div>

			<TonConnectButton style={{color: 'red'}} />
		</div>
	);
};
