import axios from 'axios'
import { useEffect, useState } from 'react'
import { Shine } from '../../../Components/Shine';

const explore = [
	{
		name: 'INVESTOR #454',
		price: 10,
		username: '@tonwinners',
		image: 'https://i.getgems.io/W-nKgna8_C_VZIv4jYrz_ys_9B5oeUe3b5zjzgknXO8/rs:fill:1000:0:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYy82NWQ0YTYyYWI3ZWUyODhhM2Y4OWQzNjAvNDUzL2ltYWdlLnBuZw',
		link: 'https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz/EQDGQl8Xvar9bUUCtXUoKiq27iufjJ7WjcLb4e7dKXgfS4L-',
	},

	{
		name: 'INVESTOR #199',
		price: 20,
		username: '@tonwinners',
		image: 'https://i.getgems.io/Vs0u1p6vABkcJeXgkQg1xfpydpSLdru5SVgfvQrAuJI/rs:fill:1000:0:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYy82NWQ0YTYyYWI3ZWUyODhhM2Y4OWQzNjAvMTk4L2ltYWdlLnBuZw',
		link: 'https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz/EQCDKjd_rVWw1cYB94ulNgsJATJagGliuZQc_Oa50G3om2W9',
	},

	{
		name: 'INVESTOR #299',
		price: 29.9,
		username: '@tonwinners',
		image: 'https://i.getgems.io/_IoFjib5T55Cm3qHO4b4JYSsWot1xLufNx_X3C5VVOs/rs:fill:1000:0:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYy82NWQ0YTYyYWI3ZWUyODhhM2Y4OWQzNjAvMjk4L2ltYWdlLnBuZw',
		link: 'https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz/EQB1m9OB_YvcYj53L7z7aTSCxO16gZH9_tyjUFQBSRzCFc-6',
	},

	{
		name: 'INVESTOR #188',
		price: 33,
		username: '@tonwinners',
		image: 'https://i.getgems.io/lI-CJDvQSIdvNojq-2YdU0nJFUq0ruwPocKJfid-ptg/rs:fill:1000:0:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYy82NWQ0YTYyYWI3ZWUyODhhM2Y4OWQzNjAvMTg3L2ltYWdlLnBuZw',
		link: 'https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz/EQCdteBv0lnZpPVljJp2gLP5jvoII7hOnG9SrRCFbgj_aFGn',
	},

	{
		name: 'INVESTOR #337',
		price: 50,
		username: '@tonwinners',
		image: 'https://i.getgems.io/R6qECFwdbqnJvN3e6ckT_xulwmAcXBOv5TN0-9PeYY4/rs:fill:1000:0:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYy82NWQ0YTYyYWI3ZWUyODhhM2Y4OWQzNjAvMzM2L2ltYWdlLnBuZw',
		link: 'https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz/EQBIEL9I1KyYPtV820SReGy5NlFFZfsTehNQz1jYN77mxFa-',
	},

	{
		name: 'INVESTOR #83',
		price: 89,
		username: '@tonwinners',
		image: 'https://i.getgems.io/CNh4TTQ_6d8sGHJXoQICGbjbesj5looRVgPH2i3VSEQ/rs:fill:1000:0:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYy82NWQ0YTYyYWI3ZWUyODhhM2Y4OWQzNjAvODIvaW1hZ2UucG5n',
		link: 'https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz/EQBOUH1WSb_Jp8fTRXi6GtsQPjOkEEFfrnxG8l8a05GU1sKq',
	},

	{
		name: 'INVESTOR #457',
		price: 3000,
		username: '@tonwinners',
		image: 'https://i.getgems.io/Wue3EGBPAVrnQkiGF0XWs3ysnTGwpd9XCqquPuZ6_kk/rs:fill:1000:0:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYy82NWQ0YTYyYWI3ZWUyODhhM2Y4OWQzNjAvNDU2L2ltYWdlLnBuZw',
		link: 'https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz/EQDPqchVZfgLKeUk8FEpwhK6mfO5c8SlK_bi9IKUCanlDGR-',
	},

	{
		name: 'INVESTOR #153',
		price: 7500,
		username: '@tonwinners',
		image: 'https://i.getgems.io/NDuCEZxlfS_aSAaYRI3F_HQq7P88K0ykJd54HyA7K-Q/rs:fill:1000:0:1/g:ce/czM6Ly9nZXRnZW1zLW5mdC9uZnQvYy82NWQ0YTYyYWI3ZWUyODhhM2Y4OWQzNjAvMTUyL2ltYWdlLnBuZw',
		link: 'https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz/EQA_qn2KOgfodduCIuaOIy3pSSUA7NcomKGH_TkG9evjZYKU',
	},
];

export const ExploreBlock = () => {
    // const [explore, setExplore] = useState(null);
    // useEffect(() => {
    //     axios.get('https://tonwinners.com/api/explore').then(res => console.log(res.data))
    // }, [])
    return (
        <section className="explore">
            <Shine propStyles={{ width: 2866, height: 2358, bottom: -200 }} />
            <Shine propStyles={{ width: 2866, height: 2866, bottom: '-100%', opacity: 0.3, left: '-100%' }} />
            <p className="explore__title titleTier2">
                Explore <span>Profitable NFTs</span> ONLY
            </p>
            {/* <div className="explore__nav">
                {tabs.map((tab, index) => {
                    if (activeTab === tab) {
                        return (
                            <div key={index} className="blueButton">
                                {tab}
                            </div>
                        );
                    } else {
                        return (
                            <div onClick={() => handleTabChange(index)} key={index} className="blackButton">
                                <button className="blackBtnT">{tab}</button>
                                <button className="blackBtnB"></button>
                            </div>
                        );
                    }
                })}
            </div> */}

            {explore && <div className="explore__catalog">
                {explore.map((item, index) => {
                    return (
                        <div key={index} className="explore__card">
                            <div className="explore__card-image-block">
                                <img src={item.image} alt="" />
                            </div>

                            <div className="explore__card-info">
                                <p className="explore__card-name">{item.name}</p>
                                <p className="explore__card-price">{item.price} TON</p>
                            </div>
                            <p className="explore__card-username">{item.username}</p>
                            <a href={item.link} target="_blank">
                                <button className="blueButton">Collect Now</button>
                            </a>
                        </div>
                    );
                })}
            </div>}

            <button className="blackButton explore__more-btn">
                <a target="_blank" href="https://getgems.io/collection/EQDlOWp1BQlXYSzFOx_p_3Um1JbREXCp8ATUVjYBue-lc7Tz">
                    <div className="blackBtnT">View More</div>
                </a>
                <div className="blackBtnB"></div>
            </button>
        </section>
    )
}