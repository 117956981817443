export const Shine = ({ propStyles }) => {
	const mystyle = {
		background: 'radial-gradient(34.98% 34.98% at 50% 50%, #725BDA 0%, rgba(6, 19, 45, 0) 100%)',
		position: 'absolute',
		opacity: .5,
		zIndex: 0,
        pointerEvents: 'none',
		...propStyles,
	};
	return <div style={mystyle}></div>;
};
