import { Shine } from '../../../Components/Shine';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const TopProfit = () => {
	const [profit, setProfit] = useState(null)
	
	useEffect(() => {	
		axios.get('https://tonwinners.com/api/top').then((res) => {
			setProfit(res.data.data.collectionSalesTop.items)
		})
	}, []);

	return (
		<section className="profit">
			<Shine
				propStyles={{
					top: 0,
					left: 0,
					transform: 'translate(-50%, -50%)',
					width: 2370,
					height: 2370,
				}}
			/>
			<div className="profit__text">
				<h2 className="profit__title">TOP Profit Makers</h2>
				<p className="profit__description">
					Various categories of artworks that are generating the highest profits this week. The trends will be updated weekly. Don't miss
					out on the top-performing artworks every week
				</p>
			</div>

			<div className="profit__catalog">
				<Shine
					propStyles={{
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 1866,
						height: 1358,
						opacity: 0.3,
					}}
				/>
				{profit && profit.map((item, index) => {
					return (
						<div className="profit__card" key={index}>
							<div className="profit__image-block">
								<img src={item.nft.content.image.sized} alt="" />
							</div>

							<div className="profit__blurPlate">
								<div className="profit__blurPlate-content">
									<p className="profit__blurPlate-title">{item.nft.name}</p>
									<p className="profit__blurPlate-info">{`${item.nft.address.slice(0, 6)}...${item.nft.address.slice(item.nft.address.length - 5)}`}</p>
									<p className="profit__blurPlate-value">{item.typeData.price / 10 ** 9} TON</p>
								</div>
                                <a href={`https://getgems.io/collection/${item.nft.collection.address}/${item.nft.address}`} target="_blank">
                                    <button className="blueButton">Collect Now</button>
                                </a>
								
							</div>
						</div>
					);
				})}
			</div>
		</section>
	);
};
